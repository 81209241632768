<template>
    <div class="auth">
       <div class="navTitle">
           <img src="@/assets/img/arrl.png" alt="" @click="goBack">信用授权管理
           <!-- <router-link to="/newAuth" class="new-auth">新增授权</router-link> -->
        </div>
        <div class="navHeader flex">
            <div class="search-box flex">          
            <input type="text" placeholder="请输入搜索关键词" v-model="searchVal" @keyup.enter="searchSubmit">
            <img src="@/assets/img/serch.png" @click="searchSubmit">
            </div>
        </div>
        <!-- <div class="card">
            <div class="company">南宁金电图腾软件有限公司</div>
            <div class="details">
                <p>银行名称：中信银行南宁北秀支行</p>
                <p>提交时间：2021-10-02 21:34:36</p>
                <p>授权查询截止时间：2021-11-02 21:29:36</p>
            </div>
            <router-link to="/creditAuthEdit" class="break">
            <img src="@/assets/img/edit.png" alt="">
            <p>编辑</p>
            </router-link>
            <img class="status" src="@/assets/img/auth_guoqi.png" alt="">
        </div> -->
        <van-tabs v-model:active="active" color="#ff6600" title-active-color="#ff6600">
            <van-tab title="线下和贷后授权申请管理">
                <div class="card" v-for="item in list2" :key="item">
                    <div class="company">{{item.enterName}}</div>
                    <div class="details">
                        <p>银行名称：{{item.bankName}}</p>
                        <p>授权类型：{{item.authType=='1'?'贷款申请':'贷后管理'}}</p>
                        <p>申请时间：{{item.creTime}}</p>
                        <p>处理时间：{{item.authTime}}</p>
                    </div>
                    <div v-show="item.authStatus=='0'" class="break break3" @click="goEdit2(item.bankName,item.offlineId)">
                        <img src="@/assets/img/edit.png" alt="">
                        <p>授权</p>
                    </div>
                    <div class="break break2" @click="showBookBtn(item)">
                        <img src="@/assets/img/progress_icon.png" alt="">
                        <p>授权书</p>
                    </div>
                    <img v-if="item.authStatus === '0'" class="status" src="@/assets/img/authsq0.png" alt="">
                    <img v-else-if="item.authStatus === '1'" class="status" src="@/assets/img/authsq1.png" alt="">
                    <img v-else class="status" src="@/assets/img/authsq2.png" alt="">
                </div>
                <div v-if="list2.length === 0" class="blank">
                    <img src="@/assets/img/gxm2.png" alt="">
                    <p>没有授权数据~</p>  
                </div>
            </van-tab>
            <van-tab title="银行授权管理">
                <div class="card" v-for="item in list" :key="item">
                    <div class="company">{{item.enterName}}</div>
                    <div class="details">
                        <p>银行名称：{{item.bankName}}</p>
                        <p>提交时间：{{item.updateTime}}</p>
                        <p>授权查询截止时间：{{item.endTime}}</p>
                    </div>
                    <div class="break" @click="goEdit(item)">
                        <img src="@/assets/img/edit.png" alt="">
                        <p>编辑</p>
                    </div>
                    <div class="break break2" @click="showBookBtn(item)">
                        <img src="@/assets/img/progress_icon.png" alt="">
                        <p>授权书</p>
                    </div>
                    <img v-if="item.status === '0'" class="status" src="@/assets/img/auth_guoqi.png" alt="">
                    <img v-else class="status" src="@/assets/img/auth_weiguoqi.png" alt="">
                </div>
                <div v-if="list.length === 0" class="blank">
                    <img src="@/assets/img/gxm2.png" alt="">
                    <p>没有授权数据~</p>  
                </div>
            </van-tab>
        </van-tabs>
    <!-- 授权书 -->
    <van-popup round v-model:show="showBook">
        <div class="pop">
            <div v-show="active===1" class="pop-num">编号：{{bookData.sqsNum}}</div>
            <div class="pop-header">企业综合信息查询授权书</div>
            <div class="pop-content">
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">特别提醒：<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;在您同意并接受《企业综合信息查询授权书》前，请您务必仔细阅读本授权书条款，一旦您点击“确定”按钮，即意味着您已充分理解所有条款的含义及相应的法律后果，同意向定向发送融资需求的或非定向发送融资需求的所有银行或其他金融机构给予本授权书的各项授权，且即使您的业务申请未被审核通过，本授权书各项授权的有效性不受影响。若您不同意授权，请直接退出申请页面。<br/></span>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">授权方：{{bookData.enterName}}</span><br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">统一社会信用代码：{{bookData.uniscid}}</span><br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="black-font">被授权方：{{bookData.sfBankName}}</span><br/>
                &nbsp;&nbsp;&nbsp;&nbsp;<span v-if="bookData.applyType=='1'" class="black-font">授权事由：因贵公司向我机构提出人民币{{bookData.creditAmt}}万元{{replaceGua(bookData.guaType)}}信贷需求，需要查询贵公司在广西征信融资服务平台的综合信用信息</span>
                <span v-else-if="bookData.applyType=='2'" class="black-font">授权事由：因合同编号为{{bookData.contractId}}的信贷业务贷后管理需要，申请查询贵公司在广西征信融资服务平台的综合信用信息</span><br/>
                &nbsp;&nbsp;&nbsp;&nbsp;一、授权内容<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;授权方同意并不可撤销的授权被授权方采集、查询、使用授权方在国家机关、政府部门、事业单位、金融机构、行业协会、社会团体等机构的相关信息。授权方同意无论融资业务申请是否获批，本授权书各项授权的有效性不受影响，且被授权方无需签退本授权书及其他业务申请资料。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;二、授权目的、形式与期限<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（一）授权方知悉并同意本授权书以数据电文形式订立。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（二）授权内容相关信息用于授权方融资相关业务管理。本授权书自授权之日起生效，有效期持续至授权方基于本授权书向被授权方申请办理的融资业务结清全部欠款并终止授权方业务为止（截止之日止）。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;三、其他声明<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（一）授权内容相关信息仅供参考，对授权内容相关信息有歧义，双方应友好协商。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（二）授权方在此声明已知悉并理解本授权书，以及授权被授权方采集、查询、使用其非公开信息及负面信息可能导致的任何不利后果。<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;（三）本授权书是授权方向被授权方做出的单方承诺，在授权期限内不可撤销。<br/>
                <div v-show="active===1" class="pop-num">授权时间：{{bookData.authTime}}</div>
            </div>
            <div class="pop-footer">
                <div class="back" @click="closePop">关闭</div>
            </div>
        </div>
    </van-popup>
    <!-- 授权 -->
    <van-popup round v-model:show="showShouquan">
        <div class="shouquan">
            <div class="shouquan-content">是否通过对【{{showShouquanName}}】的授权？</div>
            <div class="shouquan-footer">
                <div class="btn" @click="closeShouquan(2)">取消</div>
                <div class="btn btn2" @click="closeShouquan(0)">不通过</div>
                <div class="btn btn3" @click="closeShouquan(1)">通过</div>
            </div>
        </div>
    </van-popup>

    </div>
</template>
<script>
import { ref } from 'vue';
import { apiBankendtimeList,apiOfflineList,apiSaveOfflineAuth,replaceGua } from '../../api/axios';
import { Toast,Dialog } from 'vant';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
export default {
    setup() {
    let router = useRouter();
    let store = useStore();
    const active = ref(0);
    const showBook = ref(false);
    const showShouquan = ref(false);
    const showShouquanName = ref('');
    const showShouquanOfflineId = ref('');
    const list = ref([]);
    const list2= ref([]);
    const bookData = ref({});
    apiBankendtimeList({}).then(res => {                 
        if(res.code == '1'){
            console.log(res.info,'xianshang')
            list.value = res.info.resultList;
         }
         else {
           Toast(res.msg);
         }
    });

    apiOfflineList({}).then(res => {           
        if(res.code == '1'){
            console.log(res.info,'xianxia')
            list2.value = res.info.resultList;
        }
        else {
        Toast(res.msg);
        }
    });

    const getNews = ()=> {
        
        apiBankendtimeList({}).then(res => {                 
            if(res.code == '1'){
                list.value.splice(0,list.value.length);
                list.value = res.info.resultList;
            }
            else {
                list.value.splice(0,list.value.length);
                Toast(res.msg);
            }
        });

        apiOfflineList({}).then(res => {           
            if(res.code == '1'){
                list2.value.splice(0,list2.value.length);
                list2.value = res.info.resultList;
            }
            else {
                list2.value.splice(0,list2.value.length);
                Toast(res.msg);
            }
        });
    }
    
    //编辑
      const goEdit = (item) =>{
        let obj = JSON.stringify(item);
        // console.log(obj2);
        router.push({
          path: '/creditAuthEdit',
          query: {
            id: encodeURIComponent(obj)
          }
        });        
      };
      //授权
    const goEdit2 = (val,id) =>{
       showShouquanName.value = val;
       showShouquanOfflineId.value = id;
       showShouquan.value=true               
    };
    const closeShouquan = (val) =>{
        var oId = showShouquanOfflineId.value.toString();
        showShouquan.value=false;
        if(val === 1) {
            apiSaveOfflineAuth({offlineId:oId,authResult:'1'}).then(res => {
                if(res.code == '1'){
                    Toast('操作成功');
                    getNews();
                }
                else {
                    Toast(res.msg);
                }
            });
        }else if(val ===0) {
            Dialog.confirm({
                message:'授权不通过，机构无法为您提供更优质的服务，是否确认不授权？',
            })
            .then(() => {
                apiSaveOfflineAuth({offlineId:oId,authResult:'0'}).then(res => {
                    if(res.code == '1'){
                        Toast('操作成功');
                        getNews();
                    }
                    else {
                        Toast(res.msg);
                    }
                });
            })
            .catch(() => {
                console.log('取消');
            });
            
        }else {
            return;
        }
        
    }
    //搜索框键入
    const searchVal = ref('');
    const searchSubmit = () => {
      console.log('搜索值为：',searchVal.value);
    };
    const showBookBtn = (item)=>{
        bookData.value = item;
        showBook.value = true;
    }
    const closePop = () =>{
        showBook.value = false;
    }
    const goBack = () =>{
        if(router.currentRoute.value.params.page == 'back') {
                router.go(-1);
        }else if(store.state.isiOS) {
            try {
                window.webkit.messageHandlers.back.postMessage(null);
            } catch (e) {
                alert('跳转返回ios失败');
            }
        }else {
            router.push({
                name: 'User'
            })
        }
    }
    return {
      list,
      list2,
      active,
      bookData,
      showBook,
      showShouquan,
      showShouquanName,
      showBookBtn,
      closePop,
      searchVal,
      searchSubmit,
      goEdit,
      goEdit2,
      closeShouquan,
      goBack,
      replaceGua
    };
    },

}
</script>
<style lang="less" scoped>
.auth {
    padding-top: 12.5vw;
    .navTitle {
        .new-auth {
            color: #fff;
            line-height: normal;
            position: absolute;
            right: 3vw;
            top: 3vw;
            border-radius: 4vw;
            border: 1px solid #fff;
            padding: 0.5vw 2.5vw;
        }
    }
    .blank {
        padding-top: 8vw;
        text-align: center;
        img {
            width: 35vw;
            height: auto;
        }
        p {
            font-size: 4vw;
            color: #666666;
            margin-top: 2vw;
        }
    }
    .card {
        position: relative;
        .company {
            color: #6699ff;
            font-size: 4.8vw;
            font-weight: 500;
            padding-top: 2vw;
        }
        .details {
            width: 100%;
            font-size: 4vw;
            color: #666666;
            padding: 2vw 0;
            p {
            margin: 1.5vw 0;
            }
            
        }
        .break {
            display: inline-flex;
            align-items: center;
            background: linear-gradient(to right, #ff9900, #ff6700);
            color: #fff;
            font-size: 4vw;
            padding: 2vw 4vw;
            border-radius: 4vw;
            margin-right: 2vw;
            img {
                height: 4.5vw;
                margin-right: 2vw;
            }
            p {
                line-height: 4vw;
            }
        }
        .break2 {
            background:linear-gradient(to right, #6dbadb, #0599cd) !important;
        }
        .break3 {
            background: linear-gradient(to right,#2ecb41,#1fc14e) !important;
        }
        img.status {
            width: 19.5vw;
            height: auto;
            position: absolute;
            right: 0;
            top: 0;
        }

    }
    .pop {
        padding: 4vw;
        width: 85vw;
        .pop-content {
            width: 100%;
            height: 60vh;
            font-size: 4vw;
            overflow-y: auto;
            line-height: 6vw;
            letter-spacing: 1px;
            .black-font {
                font-weight: bold;
            }
        }
        .pop-num {
            font-size: 4vw;
            text-align: right;
        }
        .pop-header {
            font-size: 5vw;
            text-align: center;
            font-weight: bold;
            padding: 2vw 0;
        }
        .pop-footer {
            text-align: center;padding-top: 4vw;
            .back {
                display: inline-block;
                background: linear-gradient(to right, #ff9900, #ff6700);
                color: #fff;
                font-size: 4vw;
                padding: 1.5vw 0;
                border-radius: 5vw;
                width: 22vw;
            }
        }
    }
    .shouquan {
        width: 80vw;
        padding: 4vw;
        .shouquan-content {
            padding: 4vw 0;
            font-size: 4vw;
            text-align: center;
        }
        .shouquan-footer {
            padding-top: 4vw;
            display: flex;
            justify-content: space-around;
            .btn {
                background: linear-gradient(to right, #ff9900, #ff6700);
                color: #fff;
                font-size: 4vw;
                padding: 1.5vw 0;
                border-radius: 5vw;
                width: 22vw;
                text-align: center;
            }
            .btn2 {
                background: linear-gradient(to right, #f35e19, #ee3b05) !important;
            }
            .btn3 {
                background: linear-gradient(to right,#2ecb41,#1fc14e) !important;
            }
        }
    }
}
</style>